.pre-register-detail-container{
  display: flex;
  height: auto; 
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pre-register-title-container{
  height: 50px;
  padding-left: 20%;
}

.pre-register-title-container .text{
  text-align: right;
  font-weight: 600;
  line-height: 20px;
  font-size: 1rem;
  padding-right: 10px;
}

.pre-register-accordion-card{
  border-bottom: 1px solid black;
  height: auto;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}
.pre-register-accordion-card.active{
  border-color: #146FE5;
}

.pre-register-accordion-text{
  font-size: 13px;
  line-height: 20px;
}
.pre-register-accordion-text.active{
  font-weight: 600;
  color: #146FE5;
}

.pre-register-icon{
  width: 15px;
  height: 15px;
}

.pre-register-icon.custom-active{
  height: 2px;
}

.pre-register-icon.active{
  filter: invert(31%) sepia(69%) saturate(2271%) hue-rotate(213deg) brightness(96%) contrast(91%);
}

.overflow-anchor-none{
  overflow-anchor: none;
}

.accordion-label{
  flex: 0.8 !important;
  text-align: left;
}

.collapse-accordion{
  overflow: hidden;
  text-align: justify;
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-out;

  > * {
    overflow: hidden;
  }

  &.show-accordion {
    grid-template-rows: 1fr;
    padding-top: 1rem;
  }
  &.show-accordion ul{
    margin-bottom: 0;
  }
}

.pre-register-button-wrapper{
  position: fixed;
  bottom: 0;
  left: 50% ;
  transform: translateX(-50%) ;
  width: 100% ;
  background-color: #f3f4f7 ;
  height: 57px ;
  box-shadow: 0px -3px 4px #00000040 ;
  z-index: 9999 ;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pre-register-button{
  width: 90%;
  max-width: 350px;
}

.pre-register-banner-img{
  width: 100vw;
}



@media (min-width: 424px) {
  .pre-register-button-wrapper{
      position: relative ;
      z-index: 0 ;
      transform:none ;
      left: 0 ;
      box-shadow: none ;
  }
  .pre-register-button{
    width: 100%;
  }
  .pre-register-banner-img{
    max-width: 350px;
  }
  .pre-register-title-container{
    padding-left: 0;
   
  }
  .pre-register-title-container .text{
    text-align: center;
  }

}