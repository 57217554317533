.pre-register-banner {
  width: 350px;
  height: 175px;
}

.pre-register-background {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // filter: blur(1px);
}

.pre-register-backdrop {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: #A59C9C80;
}

.pre-register-content {
  z-index: 2;
  border-radius: 5px;
  padding: 0 2px;
  backdrop-filter: blur(1px);
  background: #989494B2;
}

.banner-title {
  -webkit-text-stroke: 0.1px white;
  -webkit-text-stroke-color: #000000;
  // stroke-opacity: 30%;
  color: white;
  font-size: 0.8em;
  font-weight: 800;
  letter-spacing: 2px;
}

.banner-title.no-ls{
  letter-spacing: 0px;
}

.banner-text-left {
  font-size: 10px;
}

.banner-subtitle {
  font-size: 1em;
  text-align: left;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: black;
}

.banner-text-right {
  line-height: 1px;
  letter-spacing: 3%;
  font-size: 10px;
  font-weight: 500;
}