.panel-married-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 5px 20px;
    background-color: #25c2e3;
    color: white;
    border-radius: 8px;
    margin: 10px 0;          
}

.ro-input-searchable-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4B4B5A;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e7f1;
    border-radius: 0.3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ro-input-focus{
    color: #4B4B5A;
    border-color: #5369f8;
    outline: 0;
}

.rotate-up{
    transform: rotate(180deg);
}

.rotate-down{
    transform: rotate(0deg);
}

.icon-dropable{
    display: inline-block;
    font-size: 16px;
    transition: transform 0.3s ease;
}
@media (max-width: 480px) {
    .ro-input-select-mobile{
        padding-left: 0;
        max-width: 30%;
        flex-basis: 30%;
    }
}