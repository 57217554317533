.pre-register-form-title {
  line-height: 18.5px;
  font-size:   15px;
}

.pre-register-form-subtitle {
  line-height: 16.5px;
  font-size:   12px;
}

.pre-register-form-label {
  height: 16px;
}

.pre-register-form-label span {
  font-size: 10px;
}

.pre-register-form-label .sub-label {
  font-size: 8px;
}

.pre-register-social-media-form .label {
  font-size: 10px;
}


.pre-register-social-media-form .sub-label {
  font-size: 8px;
}

.pre-register-form-input {
  height: 24px;

  &-text {
    height:    18px;
    font-size: 10px;
    width:     130px;
  }

  &-select {
    height:    18px;
    font-size: 10px;
    width:     130px;
  }

  &-country-code {
      height: 18px;
      width: 23px;
      background-color: #B6B3B333;
      font-size: 10px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
  }

  &-phone-number {
    height: 18px;
    width: 107px;
    font-size: 10px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &-checkbox {
    cursor: pointer;
  }

  &-checkbox input[type="checkbox"] {
    height: 10px;
    width: 10px;
    margin-right: 5px;
    align-self: center;
  }

  &-checkbox .checkbox-label {
    font-size: 8px;
  }

  // for "Lainnya" options
  &-checkbox input[type="text"] {
    height:       8px;
    width:        60px;
    background:   transparent;
    border-width: 1px;
    font-size:    8px;
  }

  &-checkbox .other-answer-text {
    font-size: 4px;
  }
}

.pre-register-form-error {
  font-size: 8px;
}

.disclaimer-title {
  font-size: 10px;
}

.disclaimer-list {
  padding: 10px;
}

ul.disclaimer-list li::marker {
  color: #4C90E9;
}

ul.disclaimer-list li span {
  font-size: 10px;
}

.success-message-card{
  background-color: #fff;
  border-radius: 10px;
  padding: 12px 18px;
  z-index: 10;
  box-shadow: 0px -4px 4px #00000040;
  width: 80%;
  min-height: 100px;
  text-align: center;
  border: 0.1px solid grey;
}

.success-message-close-button{
  background-color: #146fe5;
  width: 140px;
  height: 30px;
  border-radius: 5px;
  border: none;
  color: white;
  display: flex; 
}

.success-message-body{
  background: none;
  border: none
}