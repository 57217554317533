.ro-features span {
  line-height: 12.1px;
  font-size: 10px;
}

.ro-bank-list-container{
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-height: 200px;
  overflow-y: auto;
  border-radius: 5px;
  margin-top:  4px;

  .ro-bank-content{
    padding: 8px 12px;
    cursor: pointer;
    border-bottom: 1px solid #dee2e6;
    :hover{
      background-color: #f8f9fa;
    }
  }
}
