// class for styling title at dashboard
.dashboard-title{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin-bottom: 15px;
}

.dashboard-title-label{
    font-size: 1rem;
    font-weight: bold;
    white-space: nowrap;
}

.dashboard-title-line{
    flex-grow: 1;
    height: 1px;
    background-color: black;
}

.dashboard-title-line.minimum-part{
    flex-grow: 0.12;
}

.dashboard-list-container{ 
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.dashboard-welcome-card{
    height: 150px;
    width: 70%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 4px 4px #00000040;
    transition: transform .5s;

    &:hover{
        transform: scale(1.01);
        border: 0.1px solid grey
    }
}

.dashboard-welcome-card div span{
    font-size: 1.25em;
}

.dashboard-welcome-card-button{
    background-color: #4C9F7B;
}

// class for product card
.dashboard-product-card{
    height: 100px;
    width: 60%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 4px #00000040;
    border: none;
    padding: 0;
    transition: transform .5s;

    &:hover{
        border: 0.1px solid grey;
        transform: scale(1.01);
    }
}

.dashboard-product-card.disabled{
    color: black;

    &:hover{
        border: none;
        transform:none;
    }
}


.dashboard-product-button{
    background-color: white;
    border: none;
    height: 100%;
    z-index: 10;
    box-shadow: -1px 0px 4px #00000040;
    width: 100%; 
    flex: 0.1;   
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-product-name-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 0.8;
    padding-left: 50px;
    margin-right: 10px;
}
.dashboard-product-name-content{
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.dashboard-product-status-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0.3;
    padding-right: 20px;
}

.dashboard-due-date-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 5px;
}
.dashboard-due-date-container span{
    white-space: nowrap;

}
.dashboard-due-date-container strong{
    white-space: nowrap;
}

.dashboard-product-loan-process{
    border-radius: 5px;
    height: 25px;
    width: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-product-loan-process span{
    font-size: 1em;
    color: white;
    margin: 0 20px;
    white-space: nowrap;
}

.background-offering{
    background-color: #CDAE47;
}
.background-running{
    background-color: #146FE5;
}
.background-completed{
    background-color: #4C9F7B;
}
.background-rejected{
    background-color: #E05C5C;
}
.background-verification{
    background-color: #C9C5C5;
    border-radius: 10px;
}


// styling for loan detail
.product-loan-detail-card{
    height: auto;
    width: 60%;
    background-color: white;
    border-radius: 10px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: 0.05fr 1fr;
    box-shadow: 0px 4px 4px #00000040;
}

.product-loan-detail-button{
    background-color: white;
    border: none;
    // min-height: 150px;
    // height: 100%;
    // z-index: 10;
    // flex: 0.05;
    // align-items: stretch;
    box-shadow: 1px 0px 4px #00000040;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.product-loan-detail-description{
    // flex: 1;
    display: flex;
    flex-direction: column;
    // margin: 10px;
    gap: 10px;
    height: 100%;
    padding: 10px;
    align-items: center;
    justify-content: space-around;
}

.detail-side-desc{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.detail-side-desc.top-side{
    padding: 0 20%
}
.detail-side-desc.bottom-side{
    padding: 0 20% 0 15%;
    justify-content: space-between;
}

.detail-top-side-section{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.left-flex{
    flex: 1
}
.mid-flex{
    flex: 0.02;
}
.right-flex{
    flex: 0.2
}


.detail-divider{
    background-color: black;
    height: 1px;
    width: 100%;
}

.detail-bottom-side-section{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media (max-width: 1440px) {
    .dashboard-product-loan-process span{
        margin: 0 10px;
        text-align: center;
    }
}

@media (max-width: 1200px) {
    .detail-side-desc.top-side{
        padding: 0 15%
    }
    .detail-side-desc.bottom-side{
        padding: 0 15% 0 10%;
    }
    .detail-bottom-side-section div span{
        text-align: center;
    }
}


@media (max-width: 900px) {
    .dashboard-welcome-card{
        height: 125px;
        width: 90%;
    }
    .dashboard-welcome-card div span{
        font-size: 1.1em;
    }
    .dashboard-product-card{
        width: 80%;
    }  
    .product-loan-detail-card{
        width: 80%;
    }
}

@media (max-width: 600px) {
    // temporary hide
    // .detail-side-desc.top-side{
    //     padding: 0 10%
    // }
    // .detail-side-desc.bottom-side{
    //     padding: 0 10% 0 5%;
    // }
    // .detail-top-side-section span{
    //     font-size: 12px;
    // }
    // .detail-bottom-side-section div span{
    //     font-size: 12px;
    // }
    // .detail-bottom-side-section div strong{
    //     font-size: 12px;
    // }
    .dashboard-welcome-card{
        height: 100px;
        
    }
    .dashboard-welcome-card div span{
        font-size: 1.1em;
    }
    .dashboard-welcome-card-button{
        font-size: 1em;
    }
    .dashboard-product-name-container{
        padding-left: 30px;
    }

    .dashboard-product-loan-process span{
        font-size: 12px;
    }
    .dashboard-due-date-container span{
        font-size: 12px;
    }
    .dashboard-due-date-container strong{
        font-size: 12px;
    }
}


@media (max-width: 480px) {
    .dashboard-welcome-card{
        width: 100%;
        padding: 0 10px;
        
    }
    .dashboard-welcome-card div span{
        font-size: 1em;
    }
    .dashboard-welcome-card-button{
        font-size: 0.9em;
    }
    .dashboard-product-card{
        width: 100%;
    }
    .product-loan-detail-card{
        width: 100%; 
    }
    .dashboard-product-name-container span{
        text-align: center;
        font-size: 12px;
    }
}